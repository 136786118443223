body {
  margin: 0 ;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  
}

.world-background {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:rgb(0, 0, 0);
}

.marks .sphere {
   fill: rgba(156, 177, 254, 0.5);
   position: fixed!important;
   left: 0px!important;
   margin-left: 0px!important;
}
.marks .graticule {
  fill: none;
	stroke: #C0C0BB;
}
.marks .feature {
  fill: rgba(19, 123, 128, 0.5);
	stroke: rgba(192, 192, 187, 0.5);
}
.marks .interiors {
  fill: none;
	stroke: rgba(22, 156, 163, 0.5);
}

